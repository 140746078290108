import "./styles.scss"

import React from "react"

const Grid = () => {
  return (
    <section className="inamanagment-grid">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6">
            <div className="inamanagment-grid__logo">
              <img
                src={
                  require("assets/images/inamanagment/grid-logo.svg").default
                }
                alt=""
              />
            </div>
            <p>
              INA Management to ogólnopolski operator najmu krótko i
              długoterminowego. Każda inwestycja traktowana jest indywidualnie i
              dostosowana do konkretnej sieci obiektów. Wieloletnie
              doświadczenie pozwoliło wprowadzić odpowiednio wysokie standardy
              obsługi, co sprawia, że INA zarządza ponad 1000 lokali w całej
              Polsce.
            </p>
          </div>
          <div className="col-md-6 offset-lg-1">
            <div className="inamanagment-grid__image">
              <img
                src={
                  require("assets/images/inamanagment/grid-image-1.jpg").default
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
