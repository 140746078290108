import "./styles.scss"

import React from "react"

const short = [
  "Ustandaryzowane apartamenty pod względem wyposażenia i metrażu",
  "Profesjonalna recepcja – hotelowa obsługa gości",
  "Specjalistyczny system rezerwacyjny",
  "Bieżąca obsługa techniczna apartamentu – „złota rączka”",
  "Serwis sprzątający",
  "Dynamiczny marketing, profesjonalna prezentacja apartamentu",
  "Kompleksowa opieka nad gośćmi przed i w trakcie pobytu",
  "Revenue manager – dynamiczna zmiana cen, osiągamy najwyższą cenę przy najwyższym obłożeniu",
  "Sprzedaż bezpośrednia i wielokanałowa",
]

const long = [
  "Indywidualny opiekun nieruchomości i najemcy",
  "Bezobsługowa inwestycja",
  "Bieżąca obsługa techniczna apartamentu – „złota rączka”",
  "Serwis sprzątający",
  "Monitorowanie zużycia mediów",
  "Bezpieczna umowa najmu",
  "Profesjonalny marketing Twojego apartamentu (zdjęcia, prezentacja, reklama)",
  "Kompleksowe przygotowanie apartamentu pod wynajem",
  "Weryfikacja Najemcy pod kątem finansowym",
]

const Model = () => {
  return (
    <section className="inamanagment-model">
      <div className="container-fluid">
        <h3>
          Model współpracy efektywnego zarządzania wynajmem Państwa
          nieruchomością.
        </h3>
        <div className="row">
          <div className="col-lg-6">
            <img
              src={
                require("assets/images/inamanagment/model-image-1.jpg").default
              }
              alt=""
            />
            <h4>Wynajem krótkoterminowy:</h4>
            <ul>
              {short.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          <div className="col-lg-6">
            <img
              src={
                require("assets/images/inamanagment/model-image-2.jpg").default
              }
              alt=""
            />
            <h4>Wynajem długoterminowy / Longstay</h4>
            <ul>
              {long.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Model
