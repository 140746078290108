import React from "react"

import Layout from "components/Layout"

import HeaderBackground from "components/HeaderBackground"

import { Model, Service, Grid } from "page_components/inamanagment"
import { Baner } from "page_components/subpages"

const Inamanagment = ({ location }) => {
  const title = "Zarządzanie najmem krótko i długoterminowym"

  return (
    <Layout
      location={location}
      seo={{
        title: title,
      }}
      mainClass="narrow-layout"
    >
      <HeaderBackground
        title={title}
        header="Zarządzanie najmem krótko<br/> i długoterminowym"
        description="Inwestycja w mieszkanie na wynajem wymaga skutecznego pozyskiwania Klientów oraz bieżącej obsługi apartamentu. Wspólnie z naszym partnerem, firmą INA Management, opracowaliśmy model współpracy efektywnego zarządzania Państwa nieruchomością."
        background={
          require("assets/images/inamanagment/inamanagment-header-image.jpg")
            .default
        }
      />
      <Model />
      <Service />
      <Grid />
      <Baner
        header="Porozmawiajmy o wynajmie Twojego mieszkania"
        image={
          require("assets/images/inamanagment/baner-background.jpg").default
        }
        defaultMessage="Chcę poznać ofertę zarządzania najmem. Proszę o kontakt."
      />
    </Layout>
  )
}

export default Inamanagment
