import "./styles.scss"

import React from "react"

const data = [
  {
    icon: require("assets/images/inamanagment/service-ico-1.svg").default,
    header: "Maksymalizacja zysków<br/> z wynajmu",
  },
  {
    icon: require("assets/images/inamanagment/service-ico-2.svg").default,
    header: "Panel administracyjny<br/> i rozliczeniowy",
  },
  {
    icon: require("assets/images/inamanagment/service-ico-3.svg").default,
    header: "Stały kontakt<br/> z opiekunem obiektu",
  },
]

const Service = () => {
  return (
    <section className="inamanagment-service">
      <div className="container-fluid">
        <h3>Obsługa wynajmu z partnerem Trust Investment to:</h3>
        <h4>Dla właścicieli</h4>
        <div className="row justify-content-center">
          {data?.map((item, index) => (
            <div className="col-sm-4" key={index}>
              <div className="inamanagment-service__item">
                <div>
                  <img src={item.icon} alt="" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: item?.header }}></p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Service
